<template>
  <div>
    <v-form data-vv-scope="content">
      <v-layout row wrap>
        <v-flex xs6 class="mb-4">
          <div class="sw-h5">
            {{ $t("article_wizard.name") }}
          </div>
          <div class="mt-4 wrap-alignment-icon">
            <v-btn
              title="Left alignment"
              @click="setTitleConfig('left')"
              :class="{
                active:
                  groupArticle.article_title_alignment == 'left' ||
                  groupArticle.article_title_alignment == null,
              }"
              icon
            >
              <font-awesome-icon icon="align-left" />
            </v-btn>
            <v-btn
              title="Center alignment"
              @click="setTitleConfig('center')"
              :class="{
                active: groupArticle.article_title_alignment == 'center',
              }"
              icon
            >
              <font-awesome-icon icon="align-center" />
            </v-btn>
            <v-btn
              title="Right alignment"
              @click="setTitleConfig('right')"
              :class="{
                active: groupArticle.article_title_alignment == 'right',
              }"
              icon
            >
              <font-awesome-icon icon="align-right" />
            </v-btn>
            <v-btn
              title="Visibility"
              @click="setTitleVisibility()"
              :class="{
                active:
                  groupArticle.article_title_visibility ||
                  groupArticle.article_title_visibility == null,
              }"
              icon
            >
              <v-icon small>
                {{
                  groupArticle.article_title_visibility ||
                  groupArticle.article_title_visibility == null
                    ? "visibility"
                    : "visibility_off"
                }}
              </v-icon>
            </v-btn>
          </div>
          <v-text-field
            v-model="groupArticle.name"
            v-validate.disable="'required'"
            data-vv-name="name"
            :data-vv-as="$t('article_wizard.name')"
            :placeholder="$t('article_wizard.name_placeholder')"
            hide-details
          ></v-text-field>
          <ErrorMessages :error-messages="errors.collect('content.name')" />
        </v-flex>
        <v-flex xs12 class="mb-4">
          <v-layout row wrap>
            <v-flex xs6>
              <div class="sw-h5">{{ $t("article_wizard.subheading") }}</div>
              <v-text-field
                v-model="groupArticle.article_subheading"
                :placeholder="$t('article_wizard.subheading_placeholder')"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12>
          <div class="mb-4 sw-h5">
            {{ $t("article_wizard.body") }}
          </div>
          <QuillEditor
            v-model="groupArticle.body"
            :config="editorConfig"
            class="mb-3"
          />
          <ErrorMessages :error-messages="errors.collect('content.body')" />
        </v-flex>
        <v-flex class="xs3 mb-5">
          <v-card flat class="border-radius sw-primary-bg">
            <v-card-text class="py-5 text-center">
              <MediaManager
                v-model="groupArticle.article_image"
                :media-type="'images'"
                :label="$t('article_wizard.article_image')"
              />
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12>
          <v-switch v-model="advancedSettings" class="mb-0">
            <template slot="label">
              <div class="black--text">
                {{
                  advancedSettings
                    ? $t("common.hide_advanced_settings")
                    : $t("common.show_advanced_settings")
                }}
              </div>
            </template>
          </v-switch>
        </v-flex>
      </v-layout>
      <v-layout v-show="advancedSettings" row wrap class="pt-3">
        <v-flex xs12 class="mb-5">
          <v-layout class="row wrap">
            <v-flex xs12 class="mb-2">
              {{ $t("article_wizard.publish") }}
              <span class="sw-caption">
                {{ $t("article_wizard.publish_description") }}
              </span>
            </v-flex>
            <v-flex class="xs4 pr-5">
              <date-picker
                v-model="groupArticle.publish_at"
                @input="updatePublishUntil"
                width="99%"
                :lang="datePickerLang"
                type="datetime"
                :first-day-of-week="1"
                format="MMMM DD, YYYY H:mm"
                :minute-step="5"
                :placeholder="$t('article_wizard.publish_at')"
                :editable="false"
              ></date-picker>
            </v-flex>
            <v-flex class="xs4 pl-5">
              <date-picker
                v-model="groupArticle.publish_until"
                width="100%"
                :lang="datePickerLang"
                type="datetime"
                format="MMMM DD, YYYY H:mm"
                :minute-step="5"
                :not-before="groupArticle.publish_at"
                :placeholder="$t('article_wizard.publish_until')"
                :editable="false"
              ></date-picker>
            </v-flex>
            <v-flex class="xs12 px-2 pt-3 grey--text sw-caption"
              >{{ $t("current-timezone") }}: {{ userTimezone }}</v-flex
            >
          </v-layout>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import moment from "moment-timezone";

export default {
  inject: ["parentValidator"],
  data: () => ({
    advancedSettings: false,
  }),
  computed: {
    groupArticle: {
      get() {
        return this.$store.getters.articleWizardArticle;
      },
      set(v) {
        this.$store.dispatch("setArticleWizardArticle", v);
      },
    },
    editorConfig() {
      return {
        label: "",
        placeholder: this.$t("article_wizard.body_placeholder"),
        style: {
          height: "600px",
        },
      };
    },
    advancedSettingsLabel() {
      return this.advancedSettings
        ? this.$t("article_wizard.advanced_settings_true")
        : this.$t("article_wizard.advanced_settings_false");
    },
  },
  components: {
    DatePicker,
  },
  created() {
    this.$validator = this.parentValidator;
  },
  methods: {
    updatePublishUntil() {
      if (!this.groupArticle.publish_until) return;

      const minPublishUntil = moment(this.groupArticle.publish_at)
        .add(24, "hours")
        .toISOString();

      const untilIsSameOrBeforeMinUntil = moment(
        this.groupArticle.publish_until,
      ).isSameOrBefore(minPublishUntil);

      if (
        untilIsSameOrBeforeMinUntil ||
        this.groupArticle.publish_until === null
      ) {
        this.groupArticle.publish_until = minPublishUntil;
      }
    },
    setTitleConfig(value) {
      this.groupArticle = {
        ...this.groupArticle,
        article_title_alignment: value,
      };
    },
    setTitleVisibility() {
      this.groupArticle = {
        ...this.groupArticle,
        article_title_visibility: !Boolean(
          this.groupArticle.article_title_visibility,
        ),
      };
    },
  },
};
</script>
<style scoped lang="scss">
button {
  margin: 0;
}
button.active {
  div {
    i,
    svg {
      color: $accent !important;
    }
  }
}
.wrap-alignment-icon {
  margin-left: -10px;
}
</style>
