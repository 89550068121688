<template>
  <div class="app-page-wizard-confirmation pb-5">
    <v-layout row wrap>
      <v-flex xs12 class="mb-4">
        <div class="sw-h3 sw-accent">
          {{ $t("article_wizard.content") }}
        </div>
      </v-flex>
      <v-flex xs12>
        <v-layout row>
          <v-flex xs6 class="mb-5">
            <div class="mb-4 sw-h5">
              {{ $t("article_wizard.name") }}
            </div>
            {{ groupArticle.name }}
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex :class="bodyTextClass" :style="cssVariables">
        <div class="mb-4 sw-h5">
          {{ $t("article_wizard.body") }}
        </div>
        <div v-html="groupArticle.body" class="sw-content-typography"></div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  computed: {
    groupArticle: {
      get() {
        return this.$store.getters.articleWizardArticle;
      },
      set(val) {
        this.$store.dispatch("setArticleWizardArticle", val);
      },
    },
    bodyTextClass() {
      return this.groupArticle.body && this.groupArticle.body.length > 200
        ? "xs12"
        : "xs6";
    },
  },
};
</script>

<style lang="scss">
.app-page-wizard-confirmation {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--primary-color);
  }

  p,
  li {
    color: #000;
  }

  a {
    color: var(--secondary-color);
  }
}
</style>
