<template>
  <v-container class="general">
    <page-title :component="'ArticleListPage'">
      <template v-slot:actions>
        <LanguageSwitcher
          v-if="activeStep !== 'done'"
          ref="languageSwitcher"
          :events="activeStep !== 'confirmation'"
          @click="changeNext('stay')"
          :loading="isLoading"
          class="ml-3"
        />
        <VideoTutorial
          v-if="activeStep !== 'done'"
          :component="'AppListPage'"
          :title="$t('app-page-tutorial-title')"
          class="ml-3"
        />
        <v-btn
          v-if="activeStep === 'done'"
          round
          class="sw-secondary-bg sw-on-secondary text-none"
          @click="closeWizard"
        >
          {{ $t("article_wizard.go_to_list_page") }}
        </v-btn>
      </template>
    </page-title>
    <!-- Steps -->
    <AppStepperHeader v-model="stepper" :steps="steps" :loading="isLoading" />
    <v-window v-model="stepper" class="mb-5">
      <v-window-item
        v-for="(step, i) in steps"
        :key="`content-${i}`"
        :value="i"
      >
        <component :is="step.component" />
      </v-window-item>
    </v-window>
    <!-- Actions -->
    <div v-if="stepper < steps.length - 1">
      <v-btn
        round
        large
        class="ml-0 mr-3 white sw-accent text-none"
        @click="closeWizard"
      >
        {{ $t("common.cancel") }}
      </v-btn>
      <v-btn
        round
        large
        class="ml-0 mr-3 sw-accent-bg sw-on-accent text-none"
        :loading="isLoading"
        @click="changeNext()"
      >
        {{ nextButtonText }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import AppStepperHeader from "@/components/AppStepperHeader.vue";
import Content from "@/components/ArticleWizard/Content.vue";
import Confirmation from "@/components/ArticleWizard/Confirmation.vue";
import Done from "@/components/ArticleWizard/Done.vue";
import moment from "moment-timezone";

export default {
  provide() {
    return {
      parentValidator: this.$validator,
    };
  },
  data: () => ({
    isLoading: false,
    stepper: 0,
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    groupPluginId() {
      return this.$route.params.group_plugin_id;
    },
    groupArticleId() {
      return this.$route.params.article_id;
    },
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
    activeGroup() {
      return this.$store.getters.activeGroup;
    },
    groupArticle: {
      get() {
        return this.$store.getters.articleWizardArticle;
      },
      set(val) {
        this.$store.dispatch("setArticleWizardArticle", val);
      },
    },
    steps() {
      return [
        {
          name: "content",
          title: this.$t("article_wizard.content_title"),
          component: Content,
          enabled: true,
        },
        {
          name: "confirmation",
          title: this.$t("article_wizard.confirmation_title"),
          component: Confirmation,
          enabled: true,
        },
        {
          name: "done",
          title: this.$t("article_wizard.done_title"),
          component: Done,
          enabled: true,
        },
      ].filter((el) => el.enabled);
    },
    activeStep() {
      return this.steps[this.stepper].name;
    },
    nextButtonText() {
      if (this.activeStep !== "confirmation") {
        return this.$t("common.continue");
      }

      return this.$t("common.finish");
    },
  },
  components: {
    LanguageSwitcher,
    AppStepperHeader,
  },
  beforeCreate() {
    this.groupArticle = {
      body: "<p></p>",
      publish_at: null,
      publish_until: null,
      article_title_alignment: "left",
      article_title_visibility: true,
    };
  },
  watch: {
    groupId: {
      immediate: true,
      handler() {
        this.fetchAll();
      },
    },
    appContentLanguage: {
      handler() {
        this.fetchAll();
      },
    },
    activeStep: {
      immediate: true,
      handler() {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
    },
  },
  methods: {
    stepClass(i) {
      return [
        "pa-3 text-center step",
        {
          complete: i < this.stepper,
          editable: i < this.stepper && this.activeStep !== "done",
          active: i === this.stepper,
        },
      ];
    },
    async fetchAll() {
      try {
        this.isLoading = true;

        await this.getGroupArticle();

        this.isLoading = false;
      } catch (error) {
        this.$store.dispatch("addErrorNotification", {
          message: this.$t("common.server_error"),
        });
        this.isLoading = false;
      }
    },
    async getGroupArticle() {
      if (!this.groupArticleId) return;

      const groupArticlesGetParams = [
        this.groupId,
        this.groupArticleId,
        { with_attributes: 1, lang: this.appContentLanguage },
      ];

      const groupArticlesGettResponse = await this.$api.groupArticles.get(
        ...groupArticlesGetParams,
      );

      this.groupArticle = {
        ...groupArticlesGettResponse.data.data,
        article_title_alignment:
          groupArticlesGettResponse.data.data.attributes.article_title_alignment,
        // if null, set to true
        article_title_visibility:
          groupArticlesGettResponse.data.data.attributes.article_title_visibility || groupArticlesGettResponse.data.data.attributes.article_title_visibility == null,
      };

      this.groupArticle.article_subheading =
        this.groupArticle.attributes.article_subheading;

      // get article image

      const articleImageId = this.groupArticle.attributes.article_image;

      if (articleImageId) {
        try {
          const mediaGetParams = [articleImageId];

          const mediaGetResponse = await this.$api.media.get(...mediaGetParams);

          this.$set(
            this.groupArticle,
            "article_image",
            mediaGetResponse.data.data,
          );
        } catch (error) {}
      }
    },
    transformDateForSave(date) {
      return date ? moment(date).format("YYYY-MM-DDTHH:mm:ssZ") : null;
    },
    async createGroupArticle() {
      const specs = [
        this.groupId,
        {
          name: this.groupArticle.name,
          body: this.groupArticle.body,
          publish_at: this.transformDateForSave(this.groupArticle.publish_at),
          publish_until: this.transformDateForSave(
            this.groupArticle.publish_until,
          ),
          article_subheading: this.groupArticle.article_subheading,
          article_image:
            this.groupArticle.article_image &&
            this.groupArticle.article_image.id
              ? this.groupArticle.article_image.id
              : null,
          group_plugin_id: this.groupPluginId,
          lang: this.appContentLanguage,
          article_title_alignment: this.groupArticle.article_title_alignment,
          article_title_visibility:
            this.groupArticle.article_title_visibility,
        },
      ];

      const response = await this.$api.groupArticles.create(...specs);

      this.$router.push({
        name: "articles_edit",
        params: {
          article_id: response.data.data.id,
        },
      });
    },
    async updateGroupArticle() {
      const specs = [
        this.groupId,
        this.groupArticleId,
        {
          name: this.groupArticle.name,
          body: this.groupArticle.body,
          publish_at: this.transformDateForSave(this.groupArticle.publish_at),
          publish_until: this.transformDateForSave(
            this.groupArticle.publish_until,
          ),
          article_subheading: this.groupArticle.article_subheading,
          article_image:
            this.groupArticle.article_image &&
            this.groupArticle.article_image.id
              ? this.groupArticle.article_image.id
              : null,
          lang: this.appContentLanguage,
          article_title_alignment: this.groupArticle.article_title_alignment,
          article_title_visibility:
            this.groupArticle.article_title_visibility,
        },
      ];

      await this.$api.groupArticles.update(...specs);
    },
    closeWizard() {
      this.$router.push(
        {
          name: "articles",
        },
        () => {},
      );
    },
    async changeNext(action) {
      if (this.activeStep === "content") {
        const isValid = await this.$validator.validateAll("content");

        if (!isValid) return;

        try {
          this.isLoading = true;

          if (!this.groupArticleId) {
            await this.createGroupArticle();
          } else {
            await this.updateGroupArticle();
          }

          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.errorMessageShow(error);
          return;
        }
      }

      if (
        (this.activeStep === "confirmation" && action !== "stay") ||
        action !== "stay"
      ) {
        this.stepper += 1;
      } else {
        this.$refs.languageSwitcher.open();
      }
    },
  },
};
</script>
